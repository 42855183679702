import { getNotifications, markRead, markReadAll } from '@/services/main/notifications.service'

export default {
	namespaced: true,
    state: {
		notificationList: [],
		notificationStatus: 'pending',
		notificationTotalLatest: 0,
	},
    getters: {
		getNotificationList(state) {
			return state.notificationList;
		},
		getNotificationStatus(state) {
			return state.notificationStatus;
		},
		getNotificationTotalLatest(state) {
			return state.notificationTotalLatest;
		},
	},
    mutations: {
		SET_NOTIFICATION_LIST(state, value) {
			state.notificationList = value;
		},
		SET_NOTIFICATION_STATUS(state, value) {
			state.notificationStatus = value;
		},
		SET_NOTIFICATION_TOTAL_LATEST(state, value) {
			state.notificationTotalLatest = value;
		},
	},
    actions: {
		async fetchNotifications({ commit }, params) {
			commit('SET_NOTIFICATION_LIST', []);
			commit('SET_NOTIFICATION_STATUS', 'pending');
			try {
				const { data } = await getNotifications(params);
				commit('SET_NOTIFICATION_STATUS', 'init');
				commit('SET_NOTIFICATION_LIST', data);
				return data;
			} catch (error) {
				commit('SET_NOTIFICATION_STATUS', 'init');
				throw error;
			}
		},
		async markNotificationRead({ commit }, params) {
			commit('SET_NOTIFICATION_STATUS', 'pending');
			try {
				await markRead(params.notificationId);
				commit('SET_NOTIFICATION_STATUS', 'init');
			} catch (error) {
				commit('SET_NOTIFICATION_STATUS', 'init');
				throw error;
			}
		},
		async markNotificationsReadAll({ commit }) {
			commit('SET_NOTIFICATION_STATUS', 'pending');
			try {
				await markReadAll();
				commit('SET_NOTIFICATION_STATUS', 'init');
			} catch (error) {
				commit('SET_NOTIFICATION_STATUS', 'init');
				throw error;
			}
		},
		async fetchNotificationsTotalLatest({ commit }, params) {
			commit('SET_NOTIFICATION_TOTAL_LATEST', 0);
			commit('SET_NOTIFICATION_STATUS', 'pending');
			try {
				const { data } = await getNotifications(params);
				commit('SET_NOTIFICATION_STATUS', 'init');
				commit('SET_NOTIFICATION_TOTAL_LATEST', data);
				return data;
			} catch (error) {
				commit('SET_NOTIFICATION_STATUS', 'init');
				throw error;
			}
		},
	},
}