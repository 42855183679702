import {
	presentationByID,
	presentationCreate,
	presentationDestroy,
	presentationList,
	presentationUpdate,
} from '@/services/agency/presentations.service'

export default {
	namespaced: true,
	state: {
		// SERVICE PRESENTATION
		presentationList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		presentation: {},

		// STATUS SERVICE PRESENTATION
		presentationStatus: 'pending',
		presentationSaveStatus: 'init',
		presentationDeleteStatus: 'init',
	},
	getters: {
		// SERVICE PRESENTATION
		getPresentationList(state) {
			return state.presentationList
		},
		getPresentation(state) {
			return state.presentation
		},

		// SERVICE STATUS
		getStatusList(state) {
			return state.statusList
		},
		getStatus(state) {
			return state.status
		},

		// SERVICE PRESENTATION
		getPresentationDeleteStatus(state) {
			return state.presentationDeleteStatus
		},
		getPresentationSaveStatus(state) {
			return state.presentationSaveStatus
		},
		getPresentationStatus(state) {
			return state.presentationStatus
		},
	},
	mutations: {
		// SERVICE PRESENTATION
		PRESENTATION_LIST(state, value) {
			state.presentationList = value
		},
		SET_PRESENTATION(state, value) {
			state.presentation = value
		},

		// STATUS SERVICE PRESENTATION
		PRESENTATION_STATUS(state, value) {
			state.presentationStatus = value
		},
		PRESENTATION_SAVE_STATUS(state, value) {
			state.presentationSaveStatus = value
		},
		PRESENTATION_DELETE_STATUS(state, value) {
			state.presentationDeleteStatus = value
		},
	},
	actions: {
		// Service Presentation
		async fetchPresentationList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('PRESENTATION_STATUS', 'pending')
			commit('PRESENTATION_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await presentationList(params)
				commit('PRESENTATION_STATUS', 'init')
				commit('PRESENTATION_LIST', data)
				return data
			} catch (error) {
				commit('PRESENTATION_STATUS', 'init')
				throw error
			}
		},
		async fetchPresentationByID({ commit }, params) {
			commit('PRESENTATION_STATUS', 'pending')
			try {
				const { data } = await presentationByID(params)
				commit('PRESENTATION_STATUS', 'init')
				commit('SET_PRESENTATION', data.data)
				return data
			} catch (error) {
				commit('PRESENTATION_STATUS', 'init')

				return error
			}
		},
		async saveNewPresentation({ commit }, params) {
			commit('PRESENTATION_SAVE_STATUS', 'pending')
			try {
				const { data } = await presentationCreate(params)
				commit('PRESENTATION_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PRESENTATION_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updatePresentation({ commit }, params) {
			commit('PRESENTATION_SAVE_STATUS', 'pending')
			try {
				const { data } = await presentationUpdate(params)
				commit('PRESENTATION_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PRESENTATION_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deletePresentationByID({ commit }, params) {
			commit('PRESENTATION_DELETE_STATUS', 'pending')
			try {
				const { data } = await presentationDestroy(params)
				commit('PRESENTATION_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PRESENTATION_DELETE_STATUS', 'error')

				return error
			}
		},
	},
}
