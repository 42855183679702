import {
	benefitByID,
	benefitCreate,
	benefitDestroy,
	benefitUpdate,
	benefitsList,
	// NETWORK DOMAINS
	domainByID,
	domainCreate,
	domainDestroy,
	domainUpdate,
	domainsList,
	//FAQ
	faqcategoryByID,
	faqcategoryCreate,
	faqcategoryDestroy,
	faqcategoryUpdate,
	faqscategoriesList,
	// NETWORK PLATFORMS
	networkPlatformByID,
	networkPlatformCreate,
	networkPlatformDestroy,
	networkPlatformUpdate,
	networkPlatformsList,
	//PERMISSIONS
	permissionByID,
	permissionCreate,
	permissionDestroy,
	permissionUpdate,
	permissionsList,
	//PLANS
	planByID,
	planCreate,
	planDestroy,
	planList,
	planUpdate,
	//PRODUCTBUNDLES
	productBundleByID,
	productBundleCreate,
	productBundleDestroy,
	productBundleList,
	productBundleListInside,
	productBundleUpdate,
	//PRODUCTS
	productByID,
	productCreate,
	productDestroy,
	productList,
	productUpdate,
	//PRODUCTUSERS
	productuserByID,
	productuserCreate,
	productusersList,
	//ROLE
	roleByID,
	roleCreate,
	roleDestroy,
	roleUpdate,
	rolesList,
	// ROADMAP STATUS
	statusByID,
	statusCreate,
	statusDestroy,
	statusList,
	statusUpdate,
	// PRODUCTS Tags
	tagByID,
	tagCreate,
	tagDestroy,
	tagUpdate,
	tagsList,
	// DAY TIPS
	tipByID,
	tipCreate,
	tipDestroy,
	tipUpdate,
	tipsList,
	// DOCUMENT TYPES
	typeByID,
	typeCreate,
	typeDestroy,
	typeUpdate,
	typesList,
	// DOCUMENT WEBSITES
	websiteByID,
	websiteCreate,
	websiteDestroy,
	websiteUpdate,
	websitesList,
	// DOCUMENT WEBSITETYPES
	websitetypeByID,
	websitetypeCreate,
	websitetypeDestroy,
	websitetypeUpdate,
	websitetypesList,
	// WORKFLOW STATUS
	workflowByID,
	workflowCreate,
	workflowDestroy,
	workflowList,
	workflowUpdate,
} from '@/services/networkSystems.service'

export default {
	namespaced: true,
	state: {
		// ROLES
		rolesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		role: {},
		// STATUS ROLE
		rolesStatus: 'pending',
		rolesSaveStatus: 'init',
		rolesDeleteStatus: 'init',

		// NETWORK DOMAINS
		domainsList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		domain: {},
		// STATUS NETWORK DOMAINS
		domainsStatus: 'pending',
		domainsSaveStatus: 'init',
		domainsDeleteStatus: 'init',

		// PERMISSIONS
		permissionsList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		permission: {},
		// STATUS PERMISSION
		permissionsStatus: 'pending',
		permissionsSaveStatus: 'init',
		permissionsDeleteStatus: 'init',

		// PRODUCTUSERS
		productusersList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		productuser: {},
		// STATUS PRODUCTUSER
		productusersStatus: 'pending',
		productusersSaveStatus: 'init',

		// PRODUCT TAGS
		tagsList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		tag: {},
		// STATUS DOCUMENT TAGS
		tagsStatus: 'pending',
		tagsSaveStatus: 'init',
		tagsDeleteStatus: 'init',

		// FAQSCATEGORIES
		faqscategoriesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		faqcategory: {},
		// STATUS FAQCATEGORY
		faqscategoriesStatus: 'pending',
		faqscategoriesListStatus: 'init',
		faqscategoriesSaveStatus: 'init',
		faqscategoriesDeleteStatus: 'init',

		// BENEFITS
		benefitsList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		benefit: {},

		// STATUS BENEFITS
		benefitsStatus: 'pending',
		benefitsSaveStatus: 'init',
		benefitsDeleteStatus: 'init',

		// PLANS
		planList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		plan: {},
		// STATUS
		planStatus: 'pending',
		planSaveStatus: 'init',
		planDeleteStatus: 'init',

		// DOCUMENT TYPES
		typesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		type: {},
		// STATUS DOCUMENT TYPES
		typesStatus: 'pending',
		typesSaveStatus: 'init',
		typesDeleteStatus: 'init',

		// DOCUMENT WEBSITETYPES
		websitetypesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		websitetype: {},
		// STATUS DOCUMENT WEBSITETYPES
		websitetypesStatus: 'pending',
		websitetypesSaveStatus: 'init',
		websitetypesDeleteStatus: 'init',

		// DOCUMENT WEBSITES
		websitesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		website: {},
		// STATUS DOCUMENT WEBSITES
		websitesStatus: 'pending',
		websitesSaveStatus: 'init',
		websitesDeleteStatus: 'init',

		// NETWORK PLATFORMS
		networkPlatformsList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		networkPlatform: {},
		// STATUS NETWORK PLATFORMS
		networkPlatformsStatus: 'pending',
		networkPlatformsSaveStatus: 'init',
		networkPlatformsDeleteStatus: 'init',

		// ROADMAP STATUS
		statusList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		status: {},
		// STATUS ROADMAP STATUS
		statusStatus: 'pending',
		statusSaveStatus: 'init',
		statusDeleteStatus: 'init',

		// DAY TIPS
		tipsList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		tip: {},
		// STATUS DAY TIPS
		tipsStatus: 'pending',
		tipsSaveStatus: 'init',
		tipsDeleteStatus: 'init',

		// PRODUCTS
		productList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		product: {},
		// STATUS PRODUCTS
		productStatus: 'pending',
		productSaveStatus: 'init',
		productDeleteStatus: 'init',

		// PRODUCTS BUNDLES
		productBundleList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		productBundleListInside: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		productBundle: {},
		// STATUS PRODUCTS BUNDLES
		productBundleStatus: 'pending',
		productBundleSaveStatus: 'init',
		productBundleDeleteStatus: 'init',

		// SERVICE WORKFLOW
		workflowList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		workflow: {},

		// STATUS SERVICE WORKFLOW
		workflowStatus: 'pending',
		workflowSaveStatus: 'init',
		workflowDeleteStatus: 'init',
	},
	getters: {
		// ROLE
		getRolesList(state) {
			return state.rolesList
		},
		getRoles(state) {
			return state.role
		},
		getRolesDeleteStatus(state) {
			return state.rolesDeleteStatus
		},
		getRolesSaveStatus(state) {
			return state.rolesSaveStatus
		},
		getRolesStatus(state) {
			return state.rolesStatus
		},

		// PERMISSION
		getPermissionsList(state) {
			return state.permissionsList
		},
		getPermissions(state) {
			return state.permission
		},
		getPermissionsDeleteStatus(state) {
			return state.permissionsDeleteStatus
		},
		getPermissionsSaveStatus(state) {
			return state.permissionsSaveStatus
		},
		getPermissionsStatus(state) {
			return state.permissionsStatus
		},

		// PRODUCTUSER
		getProductusersList(state) {
			return state.productusersList
		},
		getProductusers(state) {
			return state.productuser
		},
		getProductusersSaveStatus(state) {
			return state.productusersSaveStatus
		},
		getProductusersStatus(state) {
			return state.productusersStatus
		},

		// PRODUCT TAGS
		getTagsList(state) {
			return state.tagsList
		},
		getTag(state) {
			return state.tag
		},
		// PRODUCT TAGS
		getTagsDeleteStatus(state) {
			return state.tagsDeleteStatus
		},
		getTagsSaveStatus(state) {
			return state.tagsSaveStatus
		},
		getTagsStatus(state) {
			return state.tagsStatus
		},

		// NETWORK DOMAINS
		getDomainsList(state) {
			return state.domainsList
		},
		getDomain(state) {
			return state.domain
		},
		// NETWORK DOMAINS
		getDomainsDeleteStatus(state) {
			return state.domainsDeleteStatus
		},
		getDomainsSaveStatus(state) {
			return state.domainsSaveStatus
		},
		getDomainsStatus(state) {
			return state.domainsStatus
		},

		// FAQCATEGORY
		getFaqsCategoriesList(state) {
			return state.faqscategoriesList
		},
		getFaqCategory(state) {
			return state.faqcategory
		},
		// FAQCATEGORY
		getFaqsCategoriesListStatus(state) {
			return state.faqscategoriesListStatus
		},
		getFaqsCategoriesDeleteStatus(state) {
			return state.faqscategoriesDeleteStatus
		},
		getFaqsCategoriesSaveStatus(state) {
			return state.faqscategoriesSaveStatus
		},
		getFaqsCategoriesStatus(state) {
			return state.faqscategoriesStatus
		},

		// BENEFITS
		getBenefitsList(state) {
			return state.benefitsList
		},
		getBenefit(state) {
			return state.benefit
		},

		// STATUS BENEFITS
		getBenefitsDeleteStatus(state) {
			return state.benefitsDeleteStatus
		},
		getBenefitsSaveStatus(state) {
			return state.benefitsSaveStatus
		},
		getBenefitsStatus(state) {
			return state.benefitsStatus
		},

		// PLANS
		getPlanList(state) {
			return state.planList
		},
		getPlan(state) {
			return state.plan
		},
		// PLANS STATUS
		getPlanDeleteStatus(state) {
			return state.planDeleteStatus
		},
		getPlanSaveStatus(state) {
			return state.planSaveStatus
		},
		getPlanStatus(state) {
			return state.planStatus
		},

		// DOCUMENT TYPES
		getTypesList(state) {
			return state.typesList
		},
		getType(state) {
			return state.type
		},
		// DOCUMENT TYPES
		getTypesDeleteStatus(state) {
			return state.typesDeleteStatus
		},
		getTypesSaveStatus(state) {
			return state.typesSaveStatus
		},
		getTypesStatus(state) {
			return state.typesStatus
		},

		// DOCUMENT WEBSITETYPES
		getWebsiteTypesList(state) {
			return state.websitetypesList
		},
		getWebsiteType(state) {
			return state.websitetype
		},
		// DOCUMENT WEBSITETYPES
		getWebsiteTypesDeleteStatus(state) {
			return state.websitetypesDeleteStatus
		},
		getWebsiteTypesSaveStatus(state) {
			return state.websitetypesSaveStatus
		},
		getWebsiteTypesStatus(state) {
			return state.websitetypesStatus
		},

		// DOCUMENT WEBSITES
		getWebsitesList(state) {
			return state.websitesList
		},
		getWebsite(state) {
			return state.website
		},
		// DOCUMENT WEBSITES
		getWebsitesDeleteStatus(state) {
			return state.websitesDeleteStatus
		},
		getWebsitesSaveStatus(state) {
			return state.websitesSaveStatus
		},
		getWebsitesStatus(state) {
			return state.websitesStatus
		},

		// NETWORK PLATFORMS
		getNetworkPlatformsList(state) {
			return state.networkPlatformsList
		},
		getNetworkPlatform(state) {
			return state.networkPlatform
		},
		// NETWORK PLATFORMS
		getNetworkPlatformsDeleteStatus(state) {
			return state.networkPlatformsDeleteStatus
		},
		getNetworkPlatformsSaveStatus(state) {
			return state.networkPlatformsSaveStatus
		},
		getNetworkPlatformsStatus(state) {
			return state.networkPlatformsStatus
		},

		// ROADMAP STATUS
		getStatusList(state) {
			return state.statusList
		},
		getStatus(state) {
			return state.status
		},
		// ROADMAP STATUS
		getStatusDeleteStatus(state) {
			return state.statusDeleteStatus
		},
		getStatusSaveStatus(state) {
			return state.statusSaveStatus
		},
		getStatusStatus(state) {
			return state.statusStatus
		},

		// DAY TIPS
		getTipsList(state) {
			return state.tipsList
		},
		getTip(state) {
			return state.tip
		},
		// DAY TIPS
		getTipsDeleteStatus(state) {
			return state.tipsDeleteStatus
		},
		getTipsSaveStatus(state) {
			return state.tipsSaveStatus
		},
		getTipsStatus(state) {
			return state.tipsStatus
		},

		// PRODUCTS
		getProductList(state) {
			return state.productList
		},
		getProduct(state) {
			return state.product
		},
		// PRODUCTS STATUS
		getProductDeleteStatus(state) {
			return state.productDeleteStatus
		},
		getProductSaveStatus(state) {
			return state.productSaveStatus
		},
		getProductStatus(state) {
			return state.productStatus
		},

		// PRODUCTS BUNDLES
		getProductBundleList(state) {
			return state.productBundleList
		},
		getProductBundleListInside(state) {
			return state.productBundleListInside
		},
		getProductBundle(state) {
			return state.productBundle
		},
		// PRODUCTS BUNDLES STATUS
		getProductBundleDeleteStatus(state) {
			return state.productBundleDeleteStatus
		},
		getProductBundleSaveStatus(state) {
			return state.productBundleSaveStatus
		},
		getProductBundleStatus(state) {
			return state.productBundleStatus
		},

		// SERVICE WORKFLOW
		getWorkflowList(state) {
			return state.workflowList
		},
		getWorkflow(state) {
			return state.workflow
		},

		// SERVICE STATUS
		getStatusList(state) {
			return state.statusList
		},
		getStatus(state) {
			return state.status
		},

		// SERVICE WORKFLOW
		getWorkflowDeleteStatus(state) {
			return state.workflowDeleteStatus
		},
		getWorkflowSaveStatus(state) {
			return state.workflowSaveStatus
		},
		getWorkflowStatus(state) {
			return state.workflowStatus
		},
	},
	mutations: {
		// ROLES
		ROLES_LIST(state, value) {
			state.rolesList = value
		},
		SET_ROLE(state, value) {
			state.role = value
		},
		// STATUS ROLES
		ROLES_STATUS(state, value) {
			state.rolesStatus = value
		},
		ROLE_SAVE_STATUS(state, value) {
			state.rolesSaveStatus = value
		},
		ROLE_DELETE_STATUS(state, value) {
			state.rolesDeleteStatus = value
		},

		// PERMISSIONS
		PERMISSIONS_LIST(state, value) {
			state.permissionsList = value
		},
		SET_PERMISSION(state, value) {
			state.permission = value
		},
		// STATUS PERMISSIONS
		PERMISSIONS_STATUS(state, value) {
			state.permissionsStatus = value
		},
		PERMISSION_SAVE_STATUS(state, value) {
			state.permissionsSaveStatus = value
		},
		PERMISSION_DELETE_STATUS(state, value) {
			state.permissionsDeleteStatus = value
		},

		// PRODUCTUSERS
		PRODUCTUSERS_LIST(state, value) {
			state.productusersList = value
		},
		SET_PRODUCTUSER(state, value) {
			state.productuser = value
		},
		// STATUS PRODUCTUSERS
		PRODUCTUSERS_STATUS(state, value) {
			state.productusersStatus = value
		},
		PRODUCTUSER_SAVE_STATUS(state, value) {
			state.productusersSaveStatus = value
		},

		// PRODUCT TAGS
		TAGS_LIST(state, value) {
			state.tagsList = value
		},
		SET_TAG(state, value) {
			state.tag = value
		},
		// STATUS PRODUCT TAGS
		TAGS_STATUS(state, value) {
			state.tagsStatus = value
		},
		TAG_SAVE_STATUS(state, value) {
			state.tagsSaveStatus = value
		},
		TAG_DELETE_STATUS(state, value) {
			state.tagsDeleteStatus = value
		},

		// NETWORK DOMAINS
		DOMAINS_LIST(state, value) {
			state.domainsList = value
		},
		SET_DOMAIN(state, value) {
			state.domain = value
		},
		// STATUS NETWORK DOMAINS
		DOMAINS_STATUS(state, value) {
			state.domainsStatus = value
		},
		DOMAIN_SAVE_STATUS(state, value) {
			state.domainsSaveStatus = value
		},
		DOMAIN_DELETE_STATUS(state, value) {
			state.domainsDeleteStatus = value
		},

		// FAQSCATEGORIES
		SET_FAQSCATEGORIES_LIST(state, value) {
			state.faqscategoriesList = value
		},
		SET_FAQCATEGORY(state, value) {
			state.faqcategory = value
		},
		// STATUS FAQSCATEGORIES
		FAQSCATEGORIESLIST_STATUS(state, value) {
			state.faqscategoriesListStatus = value
		},
		FAQSCATEGORIES_STATUS(state, value) {
			state.faqscategoriesStatus = value
		},
		FAQCATEGORY_SAVE_STATUS(state, value) {
			state.faqscategoriesSaveStatus = value
		},
		FAQCATEGORY_DELETE_STATUS(state, value) {
			state.faqscategoriesDeleteStatus = value
		},

		// BENEFITS
		BENEFITS_LIST(state, value) {
			state.benefitsList = value
		},
		SET_BENEFIT(state, value) {
			state.benefit = value
		},

		// STATUS BENEFITS
		BENEFITS_STATUS(state, value) {
			state.benefitsStatus = value
		},
		BENEFIT_SAVE_STATUS(state, value) {
			state.benefitsSaveStatus = value
		},
		BENEFIT_DELETE_STATUS(state, value) {
			state.benefitsDeleteStatus = value
		},

		// PLANS
		PLAN_LIST(state, value) {
			state.planList = value
		},
		SET_PLAN(state, value) {
			state.plan = value
		},
		// PLANS STATUS
		PLAN_STATUS(state, value) {
			state.planStatus = value
		},
		PLAN_SAVE_STATUS(state, value) {
			state.planSaveStatus = value
		},
		PLANS_DELETE_STATUS(state, value) {
			state.planDeleteStatus = value
		},

		// DOCUMENT TYPES
		TYPES_LIST(state, value) {
			state.typesList = value
		},
		SET_TYPE(state, value) {
			state.type = value
		},
		// STATUS DOCUMENT TYPES
		TYPES_STATUS(state, value) {
			state.typesStatus = value
		},
		TYPE_SAVE_STATUS(state, value) {
			state.typesSaveStatus = value
		},
		TYPE_DELETE_STATUS(state, value) {
			state.typesDeleteStatus = value
		},

		// DOCUMENT WEBSITETYPES
		WEBSITETYPES_LIST(state, value) {
			state.websitetypesList = value
		},
		SET_WEBSITETYPE(state, value) {
			state.websitetype = value
		},
		// STATUS DOCUMENT WEBSITETYPES
		WEBSITETYPES_STATUS(state, value) {
			state.websitetypesStatus = value
		},
		WEBSITETYPE_SAVE_STATUS(state, value) {
			state.websitetypesSaveStatus = value
		},
		WEBSITETYPE_DELETE_STATUS(state, value) {
			state.websitetypesDeleteStatus = value
		},

		// DOCUMENT WEBSITES
		WEBSITES_LIST(state, value) {
			state.websitesList = value
		},
		SET_WEBSITE(state, value) {
			state.website = value
		},
		// STATUS DOCUMENT WEBSITES
		WEBSITES_STATUS(state, value) {
			state.websitesStatus = value
		},
		WEBSITE_SAVE_STATUS(state, value) {
			state.websitesSaveStatus = value
		},
		WEBSITE_DELETE_STATUS(state, value) {
			state.websitesDeleteStatus = value
		},

		// NETWORK PLATFORMS
		NETWORKPLATFORMS_LIST(state, value) {
			state.networkPlatformsList = value
		},
		SET_NETWORKPLATFORM(state, value) {
			state.networkPlatform = value
		},
		// STATUS NETWORK PLATFORMS
		NETWORKPLATFORMS_STATUS(state, value) {
			state.networkPlatformsStatus = value
		},
		NETWORKPLATFORM_SAVE_STATUS(state, value) {
			state.networkPlatformsSaveStatus = value
		},
		NETWORKPLATFORM_DELETE_STATUS(state, value) {
			state.networkPlatformsDeleteStatus = value
		},

		// ROADMAP STATUS
		STATUS_LIST(state, value) {
			state.statusList = value
		},
		SET_STATUS(state, value) {
			state.status = value
		},
		// STATUS ROADMAP STATUS
		STATUS_STATUS(state, value) {
			state.statusStatus = value
		},
		STATUS_SAVE_STATUS(state, value) {
			state.statusSaveStatus = value
		},
		STATUS_DELETE_STATUS(state, value) {
			state.statusDeleteStatus = value
		},

		// DAY TIPS
		TIPS_LIST(state, value) {
			state.tipsList = value
		},
		SET_TIP(state, value) {
			state.tip = value
		},
		// STATUS DAY TIPS
		TIPS_STATUS(state, value) {
			state.tipsStatus = value
		},
		TIP_SAVE_STATUS(state, value) {
			state.tipsSaveStatus = value
		},
		TIP_DELETE_STATUS(state, value) {
			state.tipsDeleteStatus = value
		},

		// PRODUCTS
		PRODUCT_LIST(state, value) {
			state.productList = value
		},
		SET_PRODUCT(state, value) {
			state.product = value
		},
		// PRODUCTS STATUS
		PRODUCT_STATUS(state, value) {
			state.productStatus = value
		},
		PRODUCT_SAVE_STATUS(state, value) {
			state.productSaveStatus = value
		},
		PRODUCT_DELETE_STATUS(state, value) {
			state.productDeleteStatus = value
		},

		// PRODUCTS BUNDLES
		PRODUCT_BUNDLE_LIST(state, value) {
			state.productBundleList = value
		},
		PRODUCT_BUNDLE_LIST_INSIDE(state, value) {
			state.productBundleListInside = value
		},
		SET_PRODUCT_BUNDLE(state, value) {
			state.productBundle = value
		},
		// PRODUCTS BUNDLES STATUS
		PRODUCT_BUNDLE_STATUS(state, value) {
			state.productBundleStatus = value
		},
		PRODUCT_BUNDLE_SAVE_STATUS(state, value) {
			state.productBundleSaveStatus = value
		},
		PRODUCT_BUNDLE_DELETE_STATUS(state, value) {
			state.productBundleDeleteStatus = value
		},

		// SERVICE WORKFLOW
		WORKFLOW_LIST(state, value) {
			state.workflowList = value
		},
		SET_WORKFLOW(state, value) {
			state.workflow = value
		},

		// STATUS SERVICE WORKFLOW
		WORKFLOW_STATUS(state, value) {
			state.workflowStatus = value
		},
		WORKFLOW_SAVE_STATUS(state, value) {
			state.workflowSaveStatus = value
		},
		WORKFLOW_DELETE_STATUS(state, value) {
			state.workflowDeleteStatus = value
		},
	},
	actions: {
		// Roles
		async fetchRoleList({ commit }, params) {
			commit('ROLES_STATUS', 'pending')
			commit('ROLES_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
				data: [],
			})
			try {
				const { data } = await rolesList(params)
				commit('ROLES_STATUS', 'init')
				commit('ROLES_LIST', data)
				return data
			} catch (error) {
				commit('ROLES_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
		async fetchRoleByID({ commit }, params) {
			commit('ROLES_STATUS', 'pending')
			try {
				const { data } = await roleByID(params)
				commit('ROLES_STATUS', 'init')
				commit('SET_ROLE', data.data)
				return data
			} catch (error) {
				commit('ROLES_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewRole({ commit }, params) {
			commit('ROLE_SAVE_STATUS', 'pending')
			try {
				const { data } = await roleCreate(params)
				commit('ROLE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('ROLE_SAVE_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
		async updateRole({ commit }, params) {
			commit('ROLE_SAVE_STATUS', 'pending')
			try {
				const { data } = await roleUpdate(params)
				commit('ROLE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('ROLE_SAVE_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
		async deleteRoleByID({ commit }, params) {
			commit('ROLE_DELETE_STATUS', 'pending')
			try {
				const { data } = await roleDestroy(params)
				commit('ROLE_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('ROLE_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},

		// Permissions
		async fetchPermissionList({ commit }, params) {
			commit('PERMISSIONS_STATUS', 'pending')
			commit('PERMISSIONS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await permissionsList(params)
				commit('PERMISSIONS_STATUS', 'init')
				commit('PERMISSIONS_LIST', data)
				return data
			} catch (error) {
				commit('PERMISSIONS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
		async fetchPermissionByID({ commit }, params) {
			commit('PERMISSIONS_STATUS', 'pending')
			try {
				const { data } = await permissionByID(params)
				commit('PERMISSIONS_STATUS', 'init')
				commit('SET_PERMISSION', data.data)
				return data
			} catch (error) {
				commit('PERMISSIONS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewPermission({ commit }, params) {
			commit('PERMISSION_SAVE_STATUS', 'pending')
			try {
				const { data } = await permissionCreate(params)
				commit('PERMISSION_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PERMISSION_SAVE_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
		async updatePermission({ commit }, params) {
			commit('PERMISSION_SAVE_STATUS', 'pending')
			try {
				const { data } = await permissionUpdate(params)
				commit('PERMISSION_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PERMISSION_SAVE_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
		async deletePermissionByID({ commit }, params) {
			commit('PERMISSION_DELETE_STATUS', 'pending')
			try {
				const { data } = await permissionDestroy(params)
				commit('PERMISSION_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PERMISSION_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},

		// Productusers
		async fetchProductuserList({ commit }, params) {
			commit('PRODUCTUSERS_STATUS', 'pending')
			commit('PRODUCTUSERS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await productusersList(params)
				commit('PRODUCTUSERS_STATUS', 'init')
				commit('PRODUCTUSERS_LIST', data)
				return data
			} catch (error) {
				commit('PRODUCTUSERS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response?.data?.message || 'Error fetching user products',
					type: 'danger',
				})
				throw error
			}
		},

		async fetchProductuserByID({ commit }, params) {
			commit('PRODUCTUSERS_STATUS', 'pending')
			try {
				const { data } = await productuserByID(params)
				commit('PRODUCTUSERS_STATUS', 'init')
				commit('SET_PRODUCTUSER', data.data)
				return data
			} catch (error) {
				commit('PRODUCTUSERS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewProductuser({ commit }, params) {
			commit('PRODUCTUSER_SAVE_STATUS', 'pending')
			try {
				const { data } = await productuserCreate(params)
				commit('PRODUCTUSER_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PRODUCTUSER_SAVE_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},

		// Product Tags
		async fetchTagList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('TAGS_STATUS', 'pending')
			commit('TAGS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await tagsList(params)
				commit('TAGS_STATUS', 'init')
				commit('TAGS_LIST', data)
				return data
			} catch (error) {
				commit('TAGS_STATUS', 'init')
				throw error
			}
		},
		async fetchTagByID({ commit }, params) {
			commit('TAGS_STATUS', 'pending')
			try {
				const { data } = await tagByID(params)
				commit('TAGS_STATUS', 'init')
				commit('SET_TAG', data.data)
				return data
			} catch (error) {
				commit('TAGS_STATUS', 'init')

				return error
			}
		},
		async saveNewTag({ commit }, params) {
			commit('TAG_SAVE_STATUS', 'pending')
			try {
				const { data } = await tagCreate(params)
				commit('TAG_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TAG_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateTag({ commit }, params) {
			commit('TAG_SAVE_STATUS', 'pending')
			try {
				const { data } = await tagUpdate(params)
				commit('TAG_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TAG_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteTagByID({ commit }, params) {
			commit('TAG_DELETE_STATUS', 'pending')
			try {
				const { data } = await tagDestroy(params)
				commit('TAG_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TAG_DELETE_STATUS', 'error')

				return error
			}
		},

		// Network Domains
		async fetchDomainList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('DOMAINS_STATUS', 'pending')
			commit('DOMAINS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await domainsList(params)
				commit('DOMAINS_STATUS', 'init')
				commit('DOMAINS_LIST', data)
				return data
			} catch (error) {
				commit('DOMAINS_STATUS', 'init')
				throw error
			}
		},
		async fetchDomainByID({ commit }, params) {
			commit('DOMAINS_STATUS', 'pending')
			try {
				const { data } = await domainByID(params)
				commit('DOMAINS_STATUS', 'init')
				commit('SET_DOMAIN', data)
				return data
			} catch (error) {
				commit('DOMAINS_STATUS', 'init')

				return error
			}
		},
		async saveNewDomain({ commit }, params) {
			commit('DOMAIN_SAVE_STATUS', 'pending')
			try {
				const { data } = await domainCreate(params)
				commit('DOMAIN_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('DOMAIN_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateDomain({ commit }, params) {
			commit('DOMAIN_SAVE_STATUS', 'pending')
			try {
				const { data } = await domainUpdate(params)
				commit('DOMAIN_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('DOMAIN_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteDomainByID({ commit }, params) {
			commit('DOMAIN_DELETE_STATUS', 'pending')
			try {
				const { data } = await domainDestroy(params)
				commit('DOMAIN_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('DOMAIN_DELETE_STATUS', 'error')

				return error
			}
		},

		// FaqsCategories
		async fetchFaqCategoryList({ commit }, params) {
			commit('FAQSCATEGORIESLIST_STATUS', 'pending')
			try {
				const { data } = await faqscategoriesList(params)
				commit('FAQSCATEGORIESLIST_STATUS', 'init')
				commit('SET_FAQSCATEGORIES_LIST', data)
				return data
			} catch (error) {
				commit('FAQSCATEGORIESLIST_STATUS', 'init')
				throw error
			}
		},

		async fetchFaqCategoryByID({ commit }, params) {
			commit('FAQSCATEGORIES_STATUS', 'pending')
			try {
				const { data } = await faqcategoryByID(params)
				commit('FAQSCATEGORIES_STATUS', 'init')
				commit('SET_FAQCATEGORY', data.data)
				return data
			} catch (error) {
				commit('FAQSCATEGORIES_STATUS', 'init')
				this._vm.$root.$bvToast.toast(error.response.data.message, {
					title: 'Error',
					variant: 'danger',
					solid: true,
				})
				return error
			}
		},
		async saveNewFaqCategory({ commit }, params) {
			commit('FAQCATEGORY_SAVE_STATUS', 'pending')
			try {
				const { data } = await faqcategoryCreate(params)
				commit('FAQCATEGORY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('FAQCATEGORY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateFaqCategory({ commit }, params) {
			commit('FAQCATEGORY_SAVE_STATUS', 'pending')
			try {
				const { data } = await faqcategoryUpdate(params)
				commit('FAQCATEGORY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('FAQCATEGORY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteFaqCategoryByID({ commit }, params) {
			commit('FAQCATEGORY_DELETE_STATUS', 'pending')
			try {
				const { data } = await faqcategoryDestroy(params)
				commit('FAQCATEGORY_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('FAQCATEGORY_DELETE_STATUS', 'error')
				this._vm.$root.$bvToast.toast(error.response.data.message, {
					title: 'Error',
					variant: 'danger',
					solid: true,
				})
				return error
			}
		},

		// Benefits
		async fetchBenefitList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('BENEFITS_STATUS', 'pending')
			commit('BENEFITS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await benefitsList(params)
				commit('BENEFITS_STATUS', 'init')
				commit('BENEFITS_LIST', data)
				return data
			} catch (error) {
				commit('BENEFITS_STATUS', 'init')
				throw error
			}
		},
		async fetchBenefitByID({ commit }, params) {
			commit('BENEFITS_STATUS', 'pending')
			try {
				const { data } = await benefitByID(params)
				commit('BENEFITS_STATUS', 'init')
				commit('SET_BENEFIT', data.data)
				return data
			} catch (error) {
				commit('BENEFITS_STATUS', 'init')

				return error
			}
		},
		async saveNewBenefit({ commit }, params) {
			commit('BENEFIT_SAVE_STATUS', 'pending')
			try {
				const { data } = await benefitCreate(params)
				commit('BENEFIT_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('BENEFIT_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateBenefit({ commit }, params) {
			commit('BENEFIT_SAVE_STATUS', 'pending')
			try {
				const { data } = await benefitUpdate(params)
				commit('BENEFIT_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('BENEFIT_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteBenefitByID({ commit }, params) {
			commit('BENEFIT_DELETE_STATUS', 'pending')
			try {
				const { data } = await benefitDestroy(params)
				commit('BENEFIT_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('BENEFIT_DELETE_STATUS', 'error')

				return error
			}
		},

		// Plans
		async fetchPlanList({ commit }, params) {
			commit('PLAN_STATUS', 'pending')
			commit('PLAN_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await planList(params)
				commit('PLAN_STATUS', 'init')
				commit('PLAN_LIST', data)
				return data
			} catch (error) {
				commit('PLAN_STATUS', 'init')
				throw error
			}
		},
		async fetchPlanByID({ commit }, params) {
			commit('PLAN_STATUS', 'pending')
			try {
				const { data } = await planByID(params)
				commit('PLAN_STATUS', 'init')
				commit('SET_PLAN', data.data)
				return data
			} catch (error) {
				commit('PLAN_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewPlan({ commit }, params) {
			commit('PLAN_SAVE_STATUS', 'pending')
			try {
				const { data } = await planCreate(params)
				commit('PLAN_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PLAN_SAVE_STATUS', 'init')
				throw error
			}
		},

		async updatePlan({ commit }, params) {
			commit('PLAN_SAVE_STATUS', 'pending')
			try {
				const { data } = await planUpdate(params)
				commit('PLAN_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PLAN_SAVE_STATUS', 'init')
				throw error
			}
		},

		async deletePlanByID({ commit }, params) {
			commit('PLANS_DELETE_STATUS', 'pending')
			try {
				const { data } = await planDestroy(params)
				commit('PLANS_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PLANS_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},

		// Document Types
		async fetchTypeList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('TYPES_STATUS', 'pending')
			commit('TYPES_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await typesList(params)
				commit('TYPES_STATUS', 'init')
				commit('TYPES_LIST', data)
				return data
			} catch (error) {
				commit('TYPES_STATUS', 'init')
				throw error
			}
		},
		async fetchTypeByID({ commit }, params) {
			commit('TYPES_STATUS', 'pending')
			try {
				const { data } = await typeByID(params)
				commit('TYPES_STATUS', 'init')
				commit('SET_TYPE', data.data)
				return data
			} catch (error) {
				commit('TYPES_STATUS', 'init')

				return error
			}
		},
		async saveNewType({ commit }, params) {
			commit('TYPE_SAVE_STATUS', 'pending')
			try {
				const { data } = await typeCreate(params)
				commit('TYPE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TYPE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateType({ commit }, params) {
			commit('TYPE_SAVE_STATUS', 'pending')
			try {
				const { data } = await typeUpdate(params)
				commit('TYPE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TYPE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteTypeByID({ commit }, params) {
			commit('TYPE_DELETE_STATUS', 'pending')
			try {
				const { data } = await typeDestroy(params)
				commit('TYPE_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TYPE_DELETE_STATUS', 'error')

				return error
			}
		},

		// Document WebsiteTypes
		async fetchWebsiteTypeList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('WEBSITETYPES_STATUS', 'pending')
			commit('WEBSITETYPES_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await websitetypesList(params)
				commit('WEBSITETYPES_STATUS', 'init')
				commit('WEBSITETYPES_LIST', data)
				return data
			} catch (error) {
				commit('WEBSITETYPES_STATUS', 'init')
				throw error
			}
		},
		async fetchWebsiteTypeByID({ commit }, params) {
			commit('WEBSITETYPES_STATUS', 'pending')
			try {
				const { data } = await websitetypeByID(params)
				commit('WEBSITETYPES_STATUS', 'init')
				commit('SET_WEBSITETYPE', data.data)
				return data
			} catch (error) {
				commit('WEBSITETYPES_STATUS', 'init')

				return error
			}
		},
		async saveNewWebsiteType({ commit }, params) {
			commit('WEBSITETYPE_SAVE_STATUS', 'pending')
			try {
				const { data } = await websitetypeCreate(params)
				commit('WEBSITETYPE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('WEBSITETYPE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateWebsiteType({ commit }, params) {
			commit('WEBSITETYPE_SAVE_STATUS', 'pending')
			try {
				const { data } = await websitetypeUpdate(params)
				commit('WEBSITETYPE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('WEBSITETYPE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteWebsiteTypeByID({ commit }, params) {
			commit('WEBSITETYPE_DELETE_STATUS', 'pending')
			try {
				const { data } = await websitetypeDestroy(params)
				commit('WEBSITETYPE_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('WEBSITETYPE_DELETE_STATUS', 'error')

				return error
			}
		},

		// Document Websites
		async fetchWebsiteList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('WEBSITES_STATUS', 'pending')
			commit('WEBSITES_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await websitesList(params)
				commit('WEBSITES_STATUS', 'init')
				commit('WEBSITES_LIST', data)
				return data
			} catch (error) {
				commit('WEBSITES_STATUS', 'init')
				throw error
			}
		},
		async fetchWebsiteByID({ commit }, params) {
			commit('WEBSITES_STATUS', 'pending')
			try {
				const { data } = await websiteByID(params)
				commit('WEBSITES_STATUS', 'init')
				commit('SET_WEBSITE', data.data)
				return data
			} catch (error) {
				commit('WEBSITES_STATUS', 'init')

				return error
			}
		},
		async saveNewWebsite({ commit }, params) {
			commit('WEBSITE_SAVE_STATUS', 'pending')
			try {
				const { data } = await websiteCreate(params)
				commit('WEBSITE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('WEBSITE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateWebsite({ commit }, params) {
			commit('WEBSITE_SAVE_STATUS', 'pending')
			try {
				const { data } = await websiteUpdate(params)
				commit('WEBSITE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('WEBSITE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteWebsiteByID({ commit }, params) {
			commit('WEBSITE_DELETE_STATUS', 'pending')
			try {
				const { data } = await websiteDestroy(params)
				commit('WEBSITE_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('WEBSITE_DELETE_STATUS', 'error')

				return error
			}
		},

		// NETWORK PLATFORMS
		async fetchNetworkPlatformList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('NETWORKPLATFORMS_STATUS', 'pending')
			commit('NETWORKPLATFORMS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await networkPlatformsList(params)
				commit('NETWORKPLATFORMS_STATUS', 'init')
				commit('NETWORKPLATFORMS_LIST', data)
				return data
			} catch (error) {
				commit('NETWORKPLATFORMS_STATUS', 'init')
				throw error
			}
		},
		async fetchNetworkPlatformByID({ commit }, params) {
			commit('NETWORKPLATFORMS_STATUS', 'pending')
			try {
				const { data } = await networkPlatformByID(params)
				commit('NETWORKPLATFORMS_STATUS', 'init')
				commit('SET_NETWORKPLATFORM', data.data)
				return data
			} catch (error) {
				commit('NETWORKPLATFORMS_STATUS', 'init')

				return error
			}
		},
		async saveNewNetworkPlatform({ commit }, params) {
			commit('NETWORKPLATFORM_SAVE_STATUS', 'pending')
			try {
				const { data } = await networkPlatformCreate(params)
				commit('NETWORKPLATFORM_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('NETWORKPLATFORM_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateNetworkPlatform({ commit }, params) {
			commit('NETWORKPLATFORM_SAVE_STATUS', 'pending')
			try {
				const { data } = await networkPlatformUpdate(params)
				commit('NETWORKPLATFORM_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('NETWORKPLATFORM_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteNetworkPlatformByID({ commit }, params) {
			commit('NETWORKPLATFORM_DELETE_STATUS', 'pending')
			try {
				const { data } = await networkPlatformDestroy(params)
				commit('NETWORKPLATFORM_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('NETWORKPLATFORM_DELETE_STATUS', 'error')

				return error
			}
		},

		// ROADMAP Status
		async fetchStatusList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('STATUS_STATUS', 'pending')
			commit('STATUS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await statusList(params)
				commit('STATUS_STATUS', 'init')
				commit('STATUS_LIST', data)
				return data
			} catch (error) {
				commit('STATUS_STATUS', 'init')
				throw error
			}
		},
		async fetchStatusByID({ commit }, params) {
			commit('STATUS_STATUS', 'pending')
			try {
				const { data } = await statusByID(params)
				commit('STATUS_STATUS', 'init')
				commit('SET_STATUS', data.data)
				return data
			} catch (error) {
				commit('STATUS_STATUS', 'init')

				return error
			}
		},
		async saveNewStatus({ commit }, params) {
			commit('STATUS_SAVE_STATUS', 'pending')
			try {
				const { data } = await statusCreate(params)
				commit('STATUS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('STATUS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateStatus({ commit }, params) {
			commit('STATUS_SAVE_STATUS', 'pending')
			try {
				const { data } = await statusUpdate(params)
				commit('STATUS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('STATUS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteStatusByID({ commit }, params) {
			commit('STATUS_DELETE_STATUS', 'pending')
			try {
				const { data } = await statusDestroy(params)
				commit('STATUS_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('STATUS_DELETE_STATUS', 'error')

				return error
			}
		},

		// Day Tips
		async fetchTipList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('TIPS_STATUS', 'pending')
			commit('TIPS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await tipsList(params)
				commit('TIPS_STATUS', 'init')
				commit('TIPS_LIST', data)
				return data
			} catch (error) {
				commit('TIPS_STATUS', 'init')
				throw error
			}
		},
		async fetchTipByID({ commit }, params) {
			commit('TIPS_STATUS', 'pending')
			try {
				const { data } = await tipByID(params)
				commit('TIPS_STATUS', 'init')
				commit('SET_TIP', data.data)
				return data
			} catch (error) {
				commit('TIPS_STATUS', 'init')

				return error
			}
		},
		async saveNewTip({ commit }, params) {
			commit('TIP_SAVE_STATUS', 'pending')
			try {
				const { data } = await tipCreate(params)
				commit('TIP_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TIP_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateTip({ commit }, params) {
			commit('TIP_SAVE_STATUS', 'pending')
			try {
				const { data } = await tipUpdate(params)
				commit('TIP_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TIP_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteTipByID({ commit }, params) {
			commit('TIP_DELETE_STATUS', 'pending')
			try {
				const { data } = await tipDestroy(params)
				commit('TIP_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TIP_DELETE_STATUS', 'error')

				return error
			}
		},

		// Products
		async fetchProductList({ commit }, params) {
			commit('PRODUCT_STATUS', 'pending')
			commit('PRODUCT_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await productList(params)
				commit('PRODUCT_STATUS', 'init')
				commit('PRODUCT_LIST', data)
				return data
			} catch (error) {
				commit('PRODUCT_STATUS', 'init')
				throw error
			}
		},
		async fetchProductByID({ commit }, params) {
			commit('PRODUCT_STATUS', 'pending')
			try {
				const { data } = await productByID(params)
				commit('PRODUCT_STATUS', 'init')
				commit('SET_PRODUCT', data.data)
				return data.data
			} catch (error) {
				commit('PRODUCT_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewProduct({ commit }, params) {
			commit('PRODUCT_SAVE_STATUS', 'pending')
			try {
				const { data } = await productCreate(params)
				commit('PRODUCT_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PRODUCT_SAVE_STATUS', 'init')
				throw error
			}
		},

		async updateProduct({ commit }, params) {
			commit('PRODUCT_SAVE_STATUS', 'pending')
			try {
				const { data } = await productUpdate(params)
				commit('PRODUCT_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PRODUCT_SAVE_STATUS', 'init')
				throw error
			}
		},

		async deleteProductByID({ commit }, params) {
			commit('PRODUCT_DELETE_STATUS', 'pending')
			try {
				const { data } = await productDestroy(params)
				commit('PRODUCT_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PRODUCT_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},

		// Products Bundles
		async fetchProductBundleList({ commit }, params) {
			commit('PRODUCT_BUNDLE_STATUS', 'pending')
			commit('PRODUCT_BUNDLE_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await productBundleList(params)
				commit('PRODUCT_BUNDLE_STATUS', 'init')
				commit('PRODUCT_BUNDLE_LIST', data)
				return data
			} catch (error) {
				commit('PRODUCT_BUNDLE_STATUS', 'init')
				throw error
			}
		},
		async fetchProductBundleListInside({ commit }, params) {
			commit('PRODUCT_BUNDLE_STATUS', 'pending')
			commit('PRODUCT_BUNDLE_LIST_INSIDE', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await productBundleListInside(params)
				commit('PRODUCT_BUNDLE_STATUS', 'init')
				commit('PRODUCT_BUNDLE_LIST_INSIDE', data)
				return data
			} catch (error) {
				commit('PRODUCT_BUNDLE_STATUS', 'init')
				throw error
			}
		},
		async fetchProductBundleByID({ commit }, params) {
			commit('PRODUCT_BUNDLE_STATUS', 'pending')
			try {
				const { data } = await productBundleByID(params)
				commit('PRODUCT_BUNDLE_STATUS', 'init')
				commit('SET_PRODUCT_BUNDLE', data.data)
				return data.data
			} catch (error) {
				commit('PRODUCT_BUNDLE_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewProductBundle({ commit }, params) {
			commit('PRODUCT_BUNDLE_SAVE_STATUS', 'pending')
			try {
				const { data } = await productBundleCreate(params)
				commit('PRODUCT_BUNDLE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PRODUCT_BUNDLE_SAVE_STATUS', 'init')
				throw error
			}
		},

		async updateProductBundle({ commit }, params) {
			commit('PRODUCT_BUNDLE_SAVE_STATUS', 'pending')
			try {
				const { data } = await productBundleUpdate(params)
				commit('PRODUCT_BUNDLE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PRODUCT_BUNDLE_SAVE_STATUS', 'init')
				throw error
			}
		},

		async deleteProductBundleByID({ commit }, params) {
			commit('PRODUCT_BUNDLE_DELETE_STATUS', 'pending')
			try {
				const { data } = await productBundleDestroy(params)
				commit('PRODUCT_BUNDLE_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PRODUCT_BUNDLE_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},

		// Service Workflow
		async fetchWorkflowList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('WORKFLOW_STATUS', 'pending')
			commit('WORKFLOW_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await workflowList(params)
				commit('WORKFLOW_STATUS', 'init')
				commit('WORKFLOW_LIST', data)
				return data
			} catch (error) {
				commit('WORKFLOW_STATUS', 'init')
				throw error
			}
		},
		async fetchWorkflowByID({ commit }, params) {
			commit('WORKFLOW_STATUS', 'pending')
			try {
				const { data } = await workflowByID(params)
				commit('WORKFLOW_STATUS', 'init')
				commit('SET_WORKFLOW', data.data)
				return data
			} catch (error) {
				commit('WORKFLOW_STATUS', 'init')

				return error
			}
		},
		async saveNewWorkflow({ commit }, params) {
			commit('WORKFLOW_SAVE_STATUS', 'pending')
			try {
				const { data } = await workflowCreate(params)
				commit('WORKFLOW_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('WORKFLOW_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateWorkflow({ commit }, params) {
			commit('WORKFLOW_SAVE_STATUS', 'pending')
			try {
				const { data } = await workflowUpdate(params)
				commit('WORKFLOW_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('WORKFLOW_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteWorkflowByID({ commit }, params) {
			commit('WORKFLOW_DELETE_STATUS', 'pending')
			try {
				const { data } = await workflowDestroy(params)
				commit('WORKFLOW_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('WORKFLOW_DELETE_STATUS', 'error')

				return error
			}
		},
	},
}
