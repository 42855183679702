import { notificationCreate } from '@/services/club/notifications.service'

export default {
	namespaced: true,
	state: {
		notificationsSaveStatus: 'init',
	},
	getters: {
		getNotificationsSaveStatusClub(state) {
			return state.notificationsSaveStatus
		},
	},
	mutations: {
		NOTIFICATION_SAVE_STATUS(state, value) {
			state.notificationsSaveStatus = value
		},
	},
	actions: {
		async saveNewNotificationClub({ commit }, params) {
			commit('NOTIFICATION_SAVE_STATUS', 'pending')
			try {
				const { data } = await notificationCreate(params)
				commit('NOTIFICATION_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('NOTIFICATION_SAVE_STATUS', 'init')
				throw error
			}
		},
	},
}
