const requests = [
	{
		path: '/requests/list',
		name: 'jobs-requests-list',
		component: () => import(/* webpackChunkName: "RequestsList" */ '@/views/jobs/requests/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default requests
