import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpJobs'

// LIST Request Recruitment & Career
export const requests = (params = '') => Http.get(`/mentorships-recruitments-requests${serialize(params)}`)

// DELETE Request Recruitment & Career
export const deleteRequest = (params = '') => Http.delete(`/mentorships-recruitments-requests/${params.id}`)

// REQUEST Request Recruitment & Career
export const updateRequests = (params = '') => Http.put(`/mentorships-recruitments-requests/${params.id}`, params)
