import { deleteRequest, requests, updateRequests } from '@/services/jobs/requests.service'

export default {
	namespaced: true,
	state: {
		// Request Recruitment & Career
		requests: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		request: {},
		requestsStatus: 'init',
		deleteRequestStatus: 'init',
	},
	getters: {
		// Request Recruitment & Career
		requests(state) {
			return state.requests
		},
		request(state) {
			return state.request
		},
		requestsStatus(state) {
			return state.requestsStatus
		},
		deleteRequestStatus(state) {
			return state.deleteRequestStatus
		},
	},
	mutations: {
		// Request Recruitment & Career
		SET_REQUESTS(state, val) {
			state.requests = val
		},
		SET_REQUEST(state, val) {
			state.request = val
		},
		SET_REQUESTS_STATUS(state, val) {
			state.requestsStatus = val
		},
		SET_DELETE_REQUEST_STATUS(state, val) {
			state.deleteRequestStatus = val
		},
	},
	actions: {
		// Request Recruitment & Career
		async fetchRequests({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_REQUESTS_STATUS', 'pending')
			commit('SET_REQUESTS', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await requests(params)
				commit('SET_REQUESTS_STATUS', 'init')
				commit('SET_REQUESTS', data)
				return data
			} catch (error) {
				commit('SET_REQUESTS_STATUS', 'init')
				throw error
			}
		},

		async fetchRequest({ commit }, params) {
			commit('SET_REQUESTS_STATUS', 'pending')
			try {
				const { data } = await requests(params)
				commit('SET_REQUEST', data)
				commit('SET_REQUESTS_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_REQUESTS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
		async deleteRequestByID({ commit }, params) {
			commit('SET_DELETE_REQUEST_STATUS', 'pending')
			try {
				const { data } = await deleteRequest(params)
				commit('SET_DELETE_REQUEST_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_DELETE_REQUEST_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},

		async updateRequests({ commit }, params) {
			commit('SET_REQUESTS_STATUS', 'pending')
			try {
				const { data } = await updateRequests(params)
				commit('SET_REQUESTS_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_REQUESTS_STATUS', 'init')
				throw error
			}
		},
	},
}
