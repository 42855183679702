import { createAxiosClient } from './create.axios.client'

const baseDomain = process.env.VUE_APP_API_EXCHANGE_URL

const clientExchange = createAxiosClient({
	baseURL: baseDomain,
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
		'Content-Language': `pt-BR`,
	},
})

clientExchange.interceptors.request.use(
	config => {
		// Do something before request is sent
		const exchange = typeof window !== 'undefined' ? sessionStorage.getItem('exchange') : null
		if (exchange) {
			config.headers.common.Authorization = exchange
		}
		return config
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error)
	},
)

clientExchange.interceptors.response.use(
	res => {
		// Do something before request is sent
		if (res.status === 401) {
			//todo log out the user
		}
		const hasAuthProperty = Object.prototype.hasOwnProperty.call(res.data, 'exchange')
		if (hasAuthProperty) {
			if (typeof window !== 'undefined') {
				sessionStorage.setItem('exchange', 'bearer ' + res.data.exchange)
			}
		}
		return res
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error)
	},
)

export default clientExchange
