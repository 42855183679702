const highlightBanners = [
	{
		path: '/agency/marketing/highlights/list',
		name: 'agency-marketing-highlights-list',
		component: () => import('@/views/agency/marketing/highlights/list.vue'),
		meta: {
			layout: 'content',
		},
	},

	{
		path: '/agency/marketing/highlight-banners/list',
		name: 'agency-marketing-highlight-banners-list',
		component: () => import('@/views/agency/marketing/highlight-banners/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/agency/marketing/tv-banners/list',
		name: 'agency-marketing-tv-banners-list',
		component: () => import('@/views/agency/marketing/tv-banners/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/agency/marketing/providers/list',
		name: 'agency-marketing-providers-list',
		component: () => import('@/views/agency/marketing/providers/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/agency/marketing/campaigns/list',
		name: 'agency-marketing-campaigns-list',
		component: () => import('@/views/agency/marketing/campaigns/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/agency/marketing/campaigns/new',
		name: 'agency-marketing-campaigns-new',
		component: () => import('@/views/agency/marketing/campaigns/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/agency/marketing/campaigns/edit/:id',
		name: 'agency-marketing-campaigns-edit',
		component: () =>
			import(/* webpackChunkName: "AgencyCampaignsEdit" */ '@/views/agency/marketing/campaigns/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/agency/marketing/presentations/list',
		name: 'agency-marketing-presentations-list',
		component: () => import('@/views/agency/marketing/presentations/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/agency/marketing/presentations/new',
		name: 'agency-marketing-presentations-new',
		component: () => import('@/views/agency/marketing/presentations/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/agency/marketing/presentations/edit/:id',
		name: 'agency-marketing-presentations-edit',
		component: () =>
			import(/* webpackChunkName: "AgencyPresentationsEdit" */ '@/views/agency/marketing/presentations/new.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default highlightBanners
